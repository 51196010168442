exports.components = {
  "component---src-components-issue-js-content-file-path-content-de-issues-2024-index-mdx": () => import("./../../../src/components/Issue.js?__contentFilePath=/opt/build/repo/cntr-monitor/content/de/issues/2024/index.mdx" /* webpackChunkName: "component---src-components-issue-js-content-file-path-content-de-issues-2024-index-mdx" */),
  "component---src-components-issue-js-content-file-path-content-en-issues-2024-index-mdx": () => import("./../../../src/components/Issue.js?__contentFilePath=/opt/build/repo/cntr-monitor/content/en/issues/2024/index.mdx" /* webpackChunkName: "component---src-components-issue-js-content-file-path-content-en-issues-2024-index-mdx" */),
  "component---src-components-page-js-content-file-path-content-de-pages-accessibility-mdx": () => import("./../../../src/components/Page.js?__contentFilePath=/opt/build/repo/cntr-monitor/content/de/pages/accessibility.mdx" /* webpackChunkName: "component---src-components-page-js-content-file-path-content-de-pages-accessibility-mdx" */),
  "component---src-components-page-js-content-file-path-content-de-pages-legal-mdx": () => import("./../../../src/components/Page.js?__contentFilePath=/opt/build/repo/cntr-monitor/content/de/pages/legal.mdx" /* webpackChunkName: "component---src-components-page-js-content-file-path-content-de-pages-legal-mdx" */),
  "component---src-components-page-js-content-file-path-content-de-pages-privacy-mdx": () => import("./../../../src/components/Page.js?__contentFilePath=/opt/build/repo/cntr-monitor/content/de/pages/privacy.mdx" /* webpackChunkName: "component---src-components-page-js-content-file-path-content-de-pages-privacy-mdx" */),
  "component---src-components-page-js-content-file-path-content-de-pages-terms-mdx": () => import("./../../../src/components/Page.js?__contentFilePath=/opt/build/repo/cntr-monitor/content/de/pages/terms.mdx" /* webpackChunkName: "component---src-components-page-js-content-file-path-content-de-pages-terms-mdx" */),
  "component---src-components-page-js-content-file-path-content-en-pages-accessibility-mdx": () => import("./../../../src/components/Page.js?__contentFilePath=/opt/build/repo/cntr-monitor/content/en/pages/accessibility.mdx" /* webpackChunkName: "component---src-components-page-js-content-file-path-content-en-pages-accessibility-mdx" */),
  "component---src-components-page-js-content-file-path-content-en-pages-legal-mdx": () => import("./../../../src/components/Page.js?__contentFilePath=/opt/build/repo/cntr-monitor/content/en/pages/legal.mdx" /* webpackChunkName: "component---src-components-page-js-content-file-path-content-en-pages-legal-mdx" */),
  "component---src-components-page-js-content-file-path-content-en-pages-privacy-mdx": () => import("./../../../src/components/Page.js?__contentFilePath=/opt/build/repo/cntr-monitor/content/en/pages/privacy.mdx" /* webpackChunkName: "component---src-components-page-js-content-file-path-content-en-pages-privacy-mdx" */),
  "component---src-components-page-js-content-file-path-content-en-pages-terms-mdx": () => import("./../../../src/components/Page.js?__contentFilePath=/opt/build/repo/cntr-monitor/content/en/pages/terms.mdx" /* webpackChunkName: "component---src-components-page-js-content-file-path-content-en-pages-terms-mdx" */),
  "component---src-components-post-js-content-file-path-content-de-issues-2024-posts-0-editorial-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/cntr-monitor/content/de/issues/2024/posts/0_editorial.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-de-issues-2024-posts-0-editorial-mdx" */),
  "component---src-components-post-js-content-file-path-content-de-issues-2024-posts-1-dual-use-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/cntr-monitor/content/de/issues/2024/posts/1_dual-use.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-de-issues-2024-posts-1-dual-use-mdx" */),
  "component---src-components-post-js-content-file-path-content-de-issues-2024-posts-10-andere-instrumente-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/cntr-monitor/content/de/issues/2024/posts/10_andere-instrumente.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-de-issues-2024-posts-10-andere-instrumente-mdx" */),
  "component---src-components-post-js-content-file-path-content-de-issues-2024-posts-11-ausgewahlte-instrumente-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/cntr-monitor/content/de/issues/2024/posts/11_ausgewählte-instrumente.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-de-issues-2024-posts-11-ausgewahlte-instrumente-mdx" */),
  "component---src-components-post-js-content-file-path-content-de-issues-2024-posts-12-internationale-initiativen-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/cntr-monitor/content/de/issues/2024/posts/12_internationale-initiativen.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-de-issues-2024-posts-12-internationale-initiativen-mdx" */),
  "component---src-components-post-js-content-file-path-content-de-issues-2024-posts-2-ki-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/cntr-monitor/content/de/issues/2024/posts/2_KI.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-de-issues-2024-posts-2-ki-mdx" */),
  "component---src-components-post-js-content-file-path-content-de-issues-2024-posts-3-drohnen-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/cntr-monitor/content/de/issues/2024/posts/3_drohnen.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-de-issues-2024-posts-3-drohnen-mdx" */),
  "component---src-components-post-js-content-file-path-content-de-issues-2024-posts-4-am-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/cntr-monitor/content/de/issues/2024/posts/4_am.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-de-issues-2024-posts-4-am-mdx" */),
  "component---src-components-post-js-content-file-path-content-de-issues-2024-posts-5-bioxai-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/cntr-monitor/content/de/issues/2024/posts/5_bioxai.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-de-issues-2024-posts-5-bioxai-mdx" */),
  "component---src-components-post-js-content-file-path-content-de-issues-2024-posts-6-biotech-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/cntr-monitor/content/de/issues/2024/posts/6_biotech.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-de-issues-2024-posts-6-biotech-mdx" */),
  "component---src-components-post-js-content-file-path-content-de-issues-2024-posts-7-edt-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/cntr-monitor/content/de/issues/2024/posts/7_edt.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-de-issues-2024-posts-7-edt-mdx" */),
  "component---src-components-post-js-content-file-path-content-de-issues-2024-posts-8-oncolytic-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/cntr-monitor/content/de/issues/2024/posts/8_oncolytic.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-de-issues-2024-posts-8-oncolytic-mdx" */),
  "component---src-components-post-js-content-file-path-content-de-issues-2024-posts-9-internationale-vertrage-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/cntr-monitor/content/de/issues/2024/posts/9_internationale-verträge.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-de-issues-2024-posts-9-internationale-vertrage-mdx" */),
  "component---src-components-post-js-content-file-path-content-en-issues-2024-posts-0-editorial-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/cntr-monitor/content/en/issues/2024/posts/0_editorial.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-en-issues-2024-posts-0-editorial-mdx" */),
  "component---src-components-post-js-content-file-path-content-en-issues-2024-posts-1-dual-use-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/cntr-monitor/content/en/issues/2024/posts/1_dual-use.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-en-issues-2024-posts-1-dual-use-mdx" */),
  "component---src-components-post-js-content-file-path-content-en-issues-2024-posts-10-andere-instrumente-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/cntr-monitor/content/en/issues/2024/posts/10_andere-instrumente.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-en-issues-2024-posts-10-andere-instrumente-mdx" */),
  "component---src-components-post-js-content-file-path-content-en-issues-2024-posts-11-ausgewahlte-instrumente-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/cntr-monitor/content/en/issues/2024/posts/11_ausgewählte-instrumente.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-en-issues-2024-posts-11-ausgewahlte-instrumente-mdx" */),
  "component---src-components-post-js-content-file-path-content-en-issues-2024-posts-12-internationale-initiativen-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/cntr-monitor/content/en/issues/2024/posts/12_internationale-initiativen.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-en-issues-2024-posts-12-internationale-initiativen-mdx" */),
  "component---src-components-post-js-content-file-path-content-en-issues-2024-posts-2-ki-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/cntr-monitor/content/en/issues/2024/posts/2_KI.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-en-issues-2024-posts-2-ki-mdx" */),
  "component---src-components-post-js-content-file-path-content-en-issues-2024-posts-3-drohnen-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/cntr-monitor/content/en/issues/2024/posts/3_drohnen.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-en-issues-2024-posts-3-drohnen-mdx" */),
  "component---src-components-post-js-content-file-path-content-en-issues-2024-posts-4-am-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/cntr-monitor/content/en/issues/2024/posts/4_am.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-en-issues-2024-posts-4-am-mdx" */),
  "component---src-components-post-js-content-file-path-content-en-issues-2024-posts-5-bioxai-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/cntr-monitor/content/en/issues/2024/posts/5_bioxai.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-en-issues-2024-posts-5-bioxai-mdx" */),
  "component---src-components-post-js-content-file-path-content-en-issues-2024-posts-6-biotech-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/cntr-monitor/content/en/issues/2024/posts/6_biotech.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-en-issues-2024-posts-6-biotech-mdx" */),
  "component---src-components-post-js-content-file-path-content-en-issues-2024-posts-7-edt-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/cntr-monitor/content/en/issues/2024/posts/7_edt.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-en-issues-2024-posts-7-edt-mdx" */),
  "component---src-components-post-js-content-file-path-content-en-issues-2024-posts-8-oncolytic-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/cntr-monitor/content/en/issues/2024/posts/8_oncolytic.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-en-issues-2024-posts-8-oncolytic-mdx" */),
  "component---src-components-post-js-content-file-path-content-en-issues-2024-posts-9-internationale-vertrage-mdx": () => import("./../../../src/components/Post.js?__contentFilePath=/opt/build/repo/cntr-monitor/content/en/issues/2024/posts/9_internationale-verträge.mdx" /* webpackChunkName: "component---src-components-post-js-content-file-path-content-en-issues-2024-posts-9-internationale-vertrage-mdx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

